import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import * as TableStyle from '../../components/css/pages/pagePersonbilsutbildning.module.css'
import personbilsutbildningImg from '../../images/utbildningar/personbilsutbildning.jpg'
const Personbilsutbildning = () => (
  <Layout>
    <Seo title='Personbilsutbildning' />
    <PageHeader text='Personbilsutbildning' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <div>

            <h2>B-behörighet</h2>
            <p>
              Vår personbilsutbildning baseras på vår utbildningsplan som är godkänd av transportstyrelsen.
              För att få vara trafiklärare måste lärarna ha genomgått särskild utbildning. I dag är utbildningstiden 1,5 år.
              Alla trafiklärare som jobbar på trafikskolan har genomgått godkänd utbildning och är godkända av transportstyrelsen.
            </p>
            <p>
              En bra förutsättning är att du trivs med din lärare.
              Vi vet att själva lärandet blir enklare och roligare då.
              Trafikskolan har flera lärare, de ger eleven större frihet och möjlighet att byta om man inte skulle vara nöjd.
            </p>
            <p>
              Vi vet också att rent motoriskt skiljer sig alla människor från varandra och när det gäller bilkörning är motoriken nog så viktig.
              Vissa moment behöver också mängdtränas mer än andra, och det är där den privata övningskörningen kommer in.
              Det är viktigt att du och din lärare har en dialog så det inte uppstår några oklarheter.
            </p>
          </div>

          <div>
            <h4>Personbilsutbildningen består av:</h4>
            <ul>
              <li className='mb-0'><strong>Grunder:</strong> där du får lära dig växla, styra, bromsa och manövrera bilen på plan mark och lutning. Du kör i mindre trafikerade områden.</li>
              <li className='mb-0'><strong>Stadstrafik:</strong> där du lär dig placeringar, tillämpa regler och uppmärksamhet i mer trafikerade områden.</li>
              <li className='mb-0'><strong>Landsväg och särskilda förhållanden:</strong>där du lär dig hur du ska bete dig på landsväg, motorväg, halt väglag, mörkerkörning.</li>
            </ul>
          </div>

          <div>
            <h3>Testlektion och planering av utbildning</h3>
            <p>
              Hur mycket just du måste köra är nästan en omöjlighet att säga på förhand,
              alla har olika förutsättningar, intresse och motivation.
              Men grundregeln är att man har kört lite hemma för att läraren ska kunna testa elevens förkunskaper.
              Annars finns det inte så mycket att göra en bedömning på, utan då måste läraren uppskatta utifrån framstegen och hur eleven bearbetar sin information.
            </p>
            <p>
              Det brukar då hamna mellan 1-3 lektioner för att läraren ska kunna göra en bra uppskattning på utbildningstiden.
              Mängdträningen hemma ligger också till grund för bedömningen,
              därför är det viktigt att du övar med din privata handledare så mycket som du sagt till din lärare.
            </p>
          </div>

          <div>
            <h3>Boka Körlektioner</h3>
            <p>
              Receptionen och din trafiklärare hjälper dig att att planera hela din utbildning.
              Förutom att receptionen och din trafiklärare hjäpler dig med bokningarna går det okså bra att boka/omboka lektionerna via elevcentralen.
            </p>
            <table className={`table-borderless info ${TableStyle.table}`}>
              <tbody>
                <tr className='table-borderless'>
                  <td><h4>Körtider</h4></td>
                </tr>
                <tr>
                  <td>Måndag - Torsdag</td>
                  <td>06:30 - 20:00</td>
                </tr>
                <tr>
                  <td>Fredag</td>
                  <td>06:30 - 19:00</td>
                </tr>
                <tr>
                  <td><br /></td>
                </tr>
                <tr>
                  <td><h4>Lektioner</h4></td>
                  <td><h4>Tid</h4></td>
                </tr>
                <tr>
                  <td>Enkel</td>
                  <td>50 min</td>
                </tr>
                <tr>
                  <td>Dubbel</td>
                  <td>100 min</td>
                </tr>
                <tr>
                  <td>Trippel</td>
                  <td>150 min</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h3>Avboka körlektioner</h3>
            <p>Ska ske <strong>senast kl 14:00 arbetsdagendagen innan</strong> din bokade tid, annars kommer debitering ske till en kostnad som motsvarar lektionspriset. </p>
            <p>Om det är något som inte går att lösa eller om du är missnöjd med något, skall du be att få prata med utbildningsledaren som ansvarar för utbildningen eller med skolchefen som är ansvarig för hela trafikskolan. </p>
          </div>

        </div>

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={personbilsutbildningImg} alt='personbilsutbildning' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            <Link to='/kontakta-oss/utbildningsanmalan' className='btn btn-danger'> Gör din anmälan här</Link>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Personbilsutbildning
